<template>
  <v-card class="modal-create payment-create">
    <v-form ref="form" v-model="valid">
      <v-card-title class="d-flex justify-center">
        <span class="text-h5 text-center mb-11" v-if="selectedPaymentVariant === 'current'">Оплата пользователей</span>
        <span class="text-h5 text-center mb-11" v-else>Доплата новых пользователей</span>
      </v-card-title>
      <v-card-text class="modal-create__form">
        <div class="close-dialog" @click="closeDialog">
          <img :src="require('@/assets/img/common/close-dialog.svg')"/>
        </div>
        <v-text-field
            class="evi-text-field mb-7"
            color="#44D370"
            type="number"
            v-model="selectedUsersCount"
            :rules="selectedUsersCountRules"
        >
          <template v-slot:label>
            Количество сотрудников
          </template>
        </v-text-field>

        <div class="d-flex align-center">
          <v-text-field
              class="evi-text-field mr-5"
              color="#44D370"
              v-model.trim="promoCode"
              :error-messages="promoCodeError"
          >
            <template v-slot:label>
              Промокод
            </template>
          </v-text-field>
          <v-btn class="evi-button-green evi-button-green--fill" :disabled="!promoCode"
                 @click="checkDiscount">
            Применить
          </v-btn>
        </div>
        <div class="payment-create__info mt-12">
          <p>Доступ будет оплачен до <span>{{ paidUntil | moment('DD.MM.YYYY') }}</span></p>
          <p class="payment-create__total">
            Итого:
            <span :class="{'old-sum' : resultDiscount}" class="mr-3 ml-2">{{ sum.toFixed(2) }} ₽</span>
            <span v-if="resultDiscount">{{ resultSum.toFixed(2) || 1 }} ₽</span>
          </p>
        </div>
      </v-card-text>
      <v-card-actions class="modal-create__actions flex-column justify-start align-start">
        <v-btn class="evi-button-green payment-button evi-button-green--fill mb-7" @click="create" :disabled="!activeButton">
          {{ paymentButtonText }}
        </v-btn>
        <p>
          Нажимая эту кнопку, вы соглашаетесь с
          <a target="_blank" href="https://evisales.com/offer">офертой</a> и
          <a target="_blank" href="https://evisales.com/policy">политикой обработки персональных данных</a>.
        </p>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>

import {mapActions, mapState} from "vuex";

export default {
  name: "CreatePayment",
  props: {
    selectedPaymentVariant: {
      type: String,
    },
    selectedPlan: {
      type: Object,
    }
  },
  data: () => ({
    valid: true,
    selectedUsersCount: 0,
    promoCode: '',
    resultDiscount: null,
    promoCodeError: '',
  }),
  computed: {
    ...mapState("payments", ["paymentInfo"]),
    selectedUsersCountRules() {
      const rules = [
        (v) => !!v || 'Укажите количество сотрудников',
      ];
      if (this.selectedPaymentVariant === "current") {
        rules.push((v) => (v && v >= this.paymentInfo.active_users_count) || `Нельзя оплатить меньшее кол-во сотрудников, чем их активное число (${this.paymentInfo.active_users_count}). Вы можете убрать неактивных, уволив их в разделе "Сотрудники"`);
      }
      return rules;
    },
    activeButton () {
      if (this.paymentInfo.is_active && this.selectedPaymentVariant !== 'new') {
        return +this.selectedUsersCount >= this.paymentInfo.active_users_count;
      }
      return +this.selectedUsersCount >= 1;
    },
    paymentButtonText() {
      return this.paymentInfo.is_active && this.selectedPaymentVariant !== 'new' ? 'Продлить доступ' : 'Оплатить доступ'
    },
    sum() {
      if (this.selectedPaymentVariant === "new") {
        let price = this.selectedPlan.price_per_user / 30 * this.selectedUsersCount * this.getNumberOfDays(new Date(), this.paymentInfo.paid_until);
        return price;
      } else {
        return this.selectedUsersCount * this.selectedPlan.price_per_user * (this.selectedPlan.days_count / 30);
      }
    },
    paidUntil() {
      if (this.selectedPaymentVariant === "new") {
        return this.paymentInfo.paid_until;
      } else {
        const inputDate = new Date(this.paymentInfo.paid_until).toISOString().slice(0, 10);
        const currentDate = new Date().toISOString().slice(0, 10);

        let D;
        if (inputDate < currentDate) {
          D = new Date();
        } else {
          D = new Date(this.paymentInfo.paid_until);
        }
        D.setDate(D.getDate() + this.selectedPlan.days_count);
        return D;
      }
    },
    resultSum() {
      return Math.round(this.sum * (100 - this.resultDiscount)) / 100;
    },
  },
  methods: {
    ...mapActions({
      createPayment: 'payments/createPayment',
      checkPromocode: 'payments/checkPromocode',
    }),
    validate() {
      return !!this.$refs.form.validate();
    },
    create() {
      if (this.validate()) {
        this.createPayment({
          users_count: this.selectedUsersCount,
          plan_id: this.selectedPlan.id,
          promocode: this.promoCode,
          type: this.selectedPaymentVariant === 'current' ? 'renewal' : 'payment',
        }).then((res) => {
          window.location.href = res;
        });
      }
    },
    closeDialog() {
      this.$emit('close');
      this.$refs.form.resetValidation();
    },
    checkDiscount() {
      this.checkPromocode({promocode: this.promoCode})
          .then((r) => {
            this.resultDiscount = r.response.discount;
            this.promoCodeError = '';
          })
          .catch(() => {
            this.resultDiscount = null;
            this.promoCodeError = 'Промокод не является рабочим';
          });
    },
    getNumberOfDays(start, end) {
      const date1 = new Date(start);
      const date2 = new Date(end);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates

      return Math.round(diffInTime / oneDay);
    }
  },
  mounted() {
    if (this.selectedPaymentVariant === "new") {
      this.selectedUsersCount = 1;
    } else {
      this.selectedUsersCount = this.paymentInfo.paid_users_count;
    }
  },
  watch: {
    selectedPaymentVariant(val) {
      if (val === "new") {
        this.selectedUsersCount = 1;
      } else {
        this.selectedUsersCount = this.paymentInfo.paid_users_count;
      }
    },
  }
}
</script>

<style lang="scss">
.payment-create {
  padding: 76px 90px;

  .v-card__title {
    justify-content: flex-start !important;
    padding: 0;
  }

  .modal-create__form {
    padding: 0 !important;
  }

  .evi-button-green.payment-button {
    height: 50px !important;

    span.v-btn__content {
      font-size: 20px;
    }
    &[disabled] {
      opacity: 0.5;
    }
  }

  .v-card__actions {
    justify-content: flex-start !important;
    padding: 0;
  }

  p, p > a {
    font-weight: normal;
    font-size: 16px;
    color: $evi-headline-color;
  }

  span {
    font-weight: bold;
  }

  &__total {
    font-size: 20px !important;
    text-transform: uppercase !important;
  }

  .old-sum {
    position: relative;

    &:after {
      display: block;
      position: absolute;
      width: 66px;
      top: 10px;
      left: -5px;
      height: 1px;
      content: "";
      background: #FE0707;
      transform: rotate(-15deg);
    }
  }
}
</style>
