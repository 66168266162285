<template>
<div class="payments">
    <div class="payments__tariff d-flex flex-wrap">
        <div>
          <div class="payment-info">
            <h2 class="mb-5">Информация об оплате</h2>
            <div class="payment-info__body">
              <div class="item-page__text-field mb-2" v-if="paymentInfo">
                <small>Оплачено до</small>
                <div class="d-flex align-center">
                  <span>{{paymentInfo.paid_until | moment('DD.MM.YYYY')}}</span>
                </div>
              </div>
              <div class="item-page__text-field" v-if="paymentInfo">
                <small>Сотрудников активно \ сотрудников оплачено:</small>
                <div class="d-flex align-center">
                  <span>{{paymentInfo.active_users_count}} \ {{paymentInfo.paid_users_count}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="payments__variants">
            <div class="payments__variant" v-for="(item, index) in variants" :key="index" :style="{backgroundImage: `url(${require('@/assets/img/payments/card.png')})`}">
              <small>Цена на одного пользователя*</small>
              <h3>{{item.name}}</h3>
              <span>{{item.price_per_user}} ₽ / мес</span>
              <div class="tariff-discount" v-if="item.discount">
                <small>{{variants[0].base_price_per_user}} ₽</small>
                <div class="tariff-discount__action">
                  {{item.discount}}% выгода
                </div>
              </div>
              <v-btn class="evi-button-green evi-button-green--fill" @click="selectPlan(item)" v-if="disablePlan(item) || isActivePayment" :disabled="disablePlan(item)">
                Оплатить
              </v-btn>
              <v-btn class="evi-button-green evi-button-green--fill" @click="selectPlan(item)" v-else :disabled="disablePlan(item)">
                Оплачено
              </v-btn>
            </div>
          </div>
        </div>

        <div>
          <h2>Преимущества</h2>
          <div class="payments__benefits">
            <div class="payments__benefit" v-for="(item, index) in benefits" :key="index">
              <img  width="65" height="65" :src="require(`@/assets/img/payments/${index+1}.svg`)" alt="">
              <p>
                {{item.title}}
              </p>
            </div>
          </div>
        </div>
        <v-dialog v-model="selectPaymentModal" max-width="773px">
            <v-card class="modal-create">
                <v-card-title class="d-flex justify-center">
                    <span class="text-h5 text-center mb-11">Оплата доступа</span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                    <div class="close-dialog" @click="selectPaymentModal = false">
                        <img :src="require('@/assets/img/common/close-dialog.svg')" />
                    </div>
                    <div class="d-flex justify-space-between">
                        <div class="select-payment pointer" v-for="i in paymentVariants" :key="i.value" @click="selectPaymentVariant(i.value)">
                            <img  width="65" height="65" :src="require(`@/assets/img/payments/${i.value}.svg`)" alt="">
                            <h3>{{i.text}}</h3>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="paymentModal"
                max-width="742px"
        >
            <CreatePayment @close="paymentModal = false" :selectedPaymentVariant="selectedPaymentVariant" :selectedPlan="selectedPlan"/>
        </v-dialog>
    </div>
</div>
</template>

<script>
    import CreatePayment from "../../components/payments/CreatePayment";
    import {mapActions, mapState} from "vuex";
    export default {
        name: "Payments",
        components: {CreatePayment},
        data: () => ({
            selectPaymentModal: false,
            paymentVariants: [
                {
                    text: 'Продлить доступ',
                    value: 'current',
                },
                {
                    text: 'Докупить доступ для пользователей',
                    value: 'new',
                },
            ],
            paymentModal: false,
            benefits: [
                {
                    title: 'Неограниченное количество клиентов и сделок',
                },
                {
                    title: 'Неограниченное количество продуктов',
                },
                {
                    title: 'Обратная связь для новых идей и пожеланий, мы всегда на связи!',
                },
                {
                    title: 'Неограниченное количество интеграций с ведущими соц. сетями',
                },
                {
                    title: 'Создание и редактирование быстрых фраз',
                },
                {
                    title: 'Интуитивно понятный планер задач',
                },
                {
                    title: 'Чат для пользователей CRM и интеграция с чатом VK без задержек',
                },
                {
                    title: 'Продуманный интерфейс с возможностью переключения на темную тему',
                },
                {
                    title: 'Автоматизированная аналитика с функцией экспорта в Excel',
                },
            ],
            variants: [],
            selectedPaymentVariant: '',
            selectedPlan: null,
        }),
        computed: {
            ...mapState("payments", ["paymentInfo"]),
            isActivePayment () {
                return this.paymentInfo && !this.paymentInfo.is_active;
            }
        },
        methods: {
            ...mapActions({
                getPlans: 'payments/getPlans',
            }),
            disablePlan (i) {
                if (this.paymentInfo && this.paymentInfo.plan)
                    return i.id !== this.paymentInfo.plan.id && this.paymentInfo.is_active;
                return false;
            },
            selectPaymentVariant (val) {
                this.selectedPaymentVariant = val;
                this.selectPaymentModal = false;
                this.paymentModal = true;
            },
            selectPlan(i) {
                this.selectedPlan = i;
                this.selectPaymentModal = true;
            }
        },
        mounted () {
            this.getPlans().then((r) => {
                this.variants = r;
            })
        }
    }
</script>

<style lang="scss" scoped>

.select-payment {
    background: linear-gradient(149.26deg, #F2F2F2 27.83%, #EBF5F9 81.36%);
    border-radius: 12px;
    padding: 43px 36px;
    min-width: 263px;
    max-width: 263px;
    margin-right: 15px;
    margin-left: 15px;

    h3 {
        margin-top: 10px;
        font-weight: 600;
        font-size: 16px;
        color: $evi-headline-color;
    }
}

.payments {
    background: #FFFFFF;
    box-shadow: 0 24px 48px rgba(30, 28, 46, 0.08);
    border-radius: 32px;
    padding: 62px 0 62px 75px;

    h2 {
        font-weight: 600;
        font-size: 28px;
        color: $evi-headline-color;
        margin-left: 50px;
    }

    &__benefits {
        margin-top: 48px;
        margin-left: 50px;
        display: flex;
        flex-wrap: wrap;
        max-width: 1000px;
    }

    &__benefit {
        max-width: 220px;
        margin-bottom: 74px;

        img {
            margin-bottom: 15px;
        }

        &:not(:nth-child(3n)) {
            margin-right: 120px;
        }

        @media screen and (max-width: 1312px) {

            &:not(:nth-child(3n)) {
                margin-right: 0;
            }

            &:not(:nth-child(2n)) {
                margin-right: 120px;
            }
        }
    }

    &__variants {
        display: flex;
        flex-wrap: wrap;
        margin-top: 60px;
        margin-bottom: 50px;
    }

    &__variant {
        padding: 34px 45px;
        margin-right: 30px;
        background-repeat: no-repeat;
        background-size: 100%;
        max-width: 312px;
        min-width: 312px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 30px;
        position: relative;

        small {
            font-weight: normal;
            font-size: 14px;
            color: #808386;
        }

        h3 {
            font-weight: normal;
            font-size: 28px;
            color: $evi-headline-color;
            margin-top: 9px;
            margin-bottom: 60px;
        }

        span {
            font-weight: 600;
            font-size: 35px;
            color: $evi-headline-color;
            margin-bottom: 62px;
        }

        .evi-button-green {
            width: 100%;
        }

        .tariff-discount {
            position: absolute;
            top: 178px;
            display: flex;
            small {
                margin-right: 11px;
                position: relative;

                &:after {
                    display: block;
                    position: absolute;
                    width: 44px;
                    top: 10px;
                    left: -5px;
                    height: 1px;
                    content: '';
                    background: #FE0707;
                    transform: rotate(-15deg);
                }
            }
            &__action {
                background: #FFE02B;
                border-radius: 5px;
                font-size: 14px;
                color: #3D3D3D;
                padding: 0 8px;
            }
        }
    }

    &__code {
        max-width: 467px;
        margin-top: 46px;
    }

    .evi-button-green.v-btn--disabled {
        opacity: 0.4;
    }

    .payment-info {
        &__body {
            margin-left: 50px;

            .item-page__text-field small {
                font-size: 14px;
                font-weight: 400;
            }

            .item-page__text-field span {
                font-size: 28px;
                font-weight: 400;
            }
        }
    }
}
</style>
